<template>
  <div
    :style="`--wrap-toggle-placement: ${wrapTogglePlacement}`"
    class="filter-chips"
    ref="container"
    v-class-mod:filter-chips="{ isShowAll }"
  >
    <div class="filter-chips__chips">
      <span v-show="wrapTogglePlacement > 0" class="filter-chips__wrap-toggle" @click="isShowAll = true">
        <span :tabindex="0" class="filter-chips__wrap-toggle-btn" @keydown.enter="isShowAll = true" ref="wrapToggle">
          {{
            interpolate(translations.filterChips.more, {
              n: chips.length - wrapTogglePlacement,
            })
          }}
        </span>
      </span>
      <span
        v-for="(chip, key) in chips"
        :style="`order: ${key}${wrapTogglePlacement > 0 && key >= wrapTogglePlacement ? `;visibility: collapse;` : ``}`"
        class="filter-chips__chip"
        :key="key"
        ref="chipBtns"
      >
        <cut-text :text="chip.title" class="filter-chips__text" />
        <icon-toggle-button
          :action="chip.remove"
          :tooltip="{ title: translations.filterChips.remove }"
          icon="close"
          button-size="medium"
        />
        <loader-bars v-if="chip.isLoading" />
      </span>
      <span
        v-if="isShowAll"
        :style="`order:${chips.length}`"
        tabindex="0"
        class="filter-chips__show-less"
        @click="isShowAll = false"
        @keydown.enter="isShowAll = false"
      >
        {{ translations.filterChips.showLess }}
      </span>
    </div>
    <div v-if="hasClearAll && chips.length" class="filter-chips__options">
      <span
        tabindex="0"
        class="filter-chips__clear-all"
        @keydown.enter="$emit('clear-all')"
        @click="$emit('clear-all')"
      >
        {{ translations.filterChips.clearAll }}
      </span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

import { interpolate } from "@horizon56/translations";
import { delayFn, isHtmlElem } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";
import CutText from "@/components/content/cut-text.vue";
import LoaderBars from "@/components/loaders/loader-bars.vue";

export type Chip = { title: string; remove: () => void; isLoading?: boolean };

defineProps<{
  chips: Chip[];
  hasClearAll?: boolean;
}>();

defineEmits<{
  (e: "clear-all"): void;
}>();

const isShowAll = ref(false);
const wrapToggleBreakpoint = ref(-1);
const wrapTogglePlacement = computed(() => (isShowAll.value ? -1 : wrapToggleBreakpoint.value));

const wrapToggle = ref<HTMLElement>();
const container = ref<HTMLElement>();
const chipBtns = ref<HTMLElement[]>([]);

const updateWrapperBreakpoint = () => {
  let breakpoint = -1;
  if (
    chipBtns.value.length > 1 &&
    isHtmlElem(wrapToggle.value) &&
    isHtmlElem(container.value) &&
    isHtmlElem(chipBtns.value?.[0]?.parentElement?.children?.[1])
  ) {
    const toggleWidth = wrapToggle.value.clientWidth + 5;
    const firstOffset = chipBtns.value[0]?.parentElement?.children?.[1]?.offsetTop ?? 0;
    for (let i = 0; i < (chipBtns.value?.[0]?.parentElement?.children?.length ?? 0); i++) {
      const chip = chipBtns.value?.[0]?.parentElement?.children?.[i];
      if (isHtmlElem(chip) && chip.offsetTop !== firstOffset) {
        breakpoint = i - 1;
        break;
      }
    }
    const lastChipBeforeWrap = chipBtns.value?.[0]?.parentElement?.children?.[breakpoint];
    if (
      breakpoint > 0 &&
      isHtmlElem(lastChipBeforeWrap) &&
      lastChipBeforeWrap.offsetLeft + lastChipBeforeWrap.clientWidth + toggleWidth > container.value.clientWidth
    ) {
      breakpoint -= 1;
    }
  }
  wrapToggleBreakpoint.value = breakpoint;
};

const observer = new ResizeObserver(() => {
  updateWrapperBreakpoint();
  window.requestAnimationFrame(updateWrapperBreakpoint);
});

onMounted(async () => {
  await delayFn(() => isHtmlElem(container.value));
  if (isHtmlElem(container.value)) {
    observer.observe(container.value);
  }
  updateWrapperBreakpoint();
});

onBeforeUnmount(() => observer.disconnect());

watch(
  () => chipBtns.value.length,
  () => {
    updateWrapperBreakpoint();
    window.requestAnimationFrame(updateWrapperBreakpoint);
  },
);
</script>

<style lang="scss" scoped>
.filter-chips {
  display: flex;
  flex-flow: column;
  width: 100%;
  &__chips,
  &__options {
    display: flex;
    flex-flow: row wrap;
  }
  &__chips {
    position: relative;
    max-height: calc(var(--app-button-height-medium) + 8px);
  }
  &__text {
    line-height: 1.3;
  }
  &__wrap-toggle,
  &__clear-all,
  &__show-less {
    position: relative;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    flex-shrink: 0;
    height: var(--app-button-height-medium);
  }
  &__chip,
  &__show-less {
    margin-right: var(--app-spacing-size-xsmall);
  }
  &__chip {
    order: -1;
    padding-left: var(--app-spacing-size-medium);
    background: var(--black-10);
    border-radius: var(--app-radius-large);
    margin-bottom: var(--app-spacing-size-xsmall);
    position: relative;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    flex-shrink: 1;
    height: var(--app-button-height-medium);
  }
  &__remove {
    position: relative;
    z-index: 1;
    @include icon-toggle-button-icon($icon-close);
    @include icon-toggle-button(medium);
  }
  &__wrap-toggle-btn,
  &__show-less {
    display: flex;
    align-items: center;
    padding: 0 var(--app-spacing-size-medium);
    cursor: pointer;
    height: var(--app-button-height-medium);
    border-radius: var(--app-radius-large);
    @include focus-outline;

    &:hover {
      background: var(--black-10);
    }
  }
  &__clear-all {
    text-decoration-line: underline;
    cursor: pointer;
    border-radius: var(--app-radius-small);
    @include focus-outline;
  }
  &__show-less,
  &__clear-all {
    padding: 0 8px;
  }
  &__wrap-toggle {
    order: var(--wrap-toggle-placement);
    width: 0;
  }
  &--isShowAll #{&} {
    &__chips {
      max-height: unset;
    }
  }
}
</style>
